import React, { useState } from "react"

import Box from "@mui/material/Box"
import { Alert, Fab } from "@mui/material"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"

import "./Pledge.scss"
import api from "../../api"
import Nav from "../../components/Nav/Nav.tsx"
import Footer from "../../components/Footer/Footer.tsx"
import pledgerBg from "../../assets/images/pledgerBg.jpg"
import ChildForm from "../../components/ChildForm/ChildForm.tsx"
import GuardianForm from "../../components/GuardianForm/GuardianForm.tsx"

export default function Pledge() {
    type FormStatus = "pending guardian data" | "pending child data" | "submitting" | "successfully submitted"

    const [formStatus, setFormStatus] = useState<FormStatus>("pending guardian data")
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const initialGuardianState = {
        id: null,
        first_name: "",
        last_name: "",
        province: "",
        city: "",
        email: ""
    };
    const initialChildrenState = [{
        first_name: "",
        last_name: "",
        school_id: "",
        guardian_id: "",
        grade: "",
        next_school_id: "",
    }]

    const [guardianData, setGuardianData] = useState({ ...initialGuardianState });
    const [childrenData, setChildrenData] = useState([ ...initialChildrenState ]);

    const addNewChild = () => {
        setChildrenData([
            {
                first_name: "",
                last_name: "",
                school_id: "",
                guardian_id: "",
                grade: "",
                next_school_id: "",
            },
            ...childrenData,
        ])
    }

    const signAgain = () => {
        setFormStatus("pending guardian data")
        setGuardianData({ ...initialGuardianState });
        setChildrenData([ ...initialChildrenState ]);
    }

    const showValidationError = (error) => {
        setErrorMessage(error);
    }

    async function submit() {
        try {
            setFormStatus("submitting")

            await api.post("/pledges", { "guardian": guardianData, "children": childrenData })

            setFormStatus("successfully submitted")
        } catch (error) {
            setErrorMessage(error.response.data.error || "An unexpected error occurred. Please try again or contact info@unpluggedcanada.com if the problem persists");
            setFormStatus("pending child data");
        }
    }

    return (
        <div className="flex flex-col bg-neutral-100">
            <Nav />
            <div className="flex items-center relative justify-center w-full h-[240px] lg:h-[300px]">
                <h2 className="text-2xl lg:text-[40px] z-20 font-bold uppercase text-neutral-100">
                    SIGN THE PLEDGE
                </h2>
                <img
                    src={pledgerBg}
                    className="w-full h-full object-cover absolute top-0 right-0"
                    alt="Blue stones wallpaper"
                />
            </div>
            <main className="w-full  px-4 py-5 lg:px-[180px]  lg:py-10 max-w-[1440px] mx-auto">
                <div className=" rounded-lg flex items-center justify-center">
                    <section className="max-w-[1440px] w-full py-8 lg:py-5 px-4 lg:px-8 flex flex-col gap-10">
                        <h1 className="text-gray-700 text-center text-2xl lg:text-[32px] font-bold  ">
                            How To Sign The Pledge?
                        </h1>
                        <ol className="list-decimal ml-5 flex flex-col gap-5 text-base lg:text-xl font-normal text-neutral-700">
                            <li>
                                To sign the pledge, your child must at least be
                                in kindergarten (for now!).
                            </li>
                            <li>
                                Please sign up your children younger than 14. To
                                add another child to a pledge form, simply
                                select “add another child” on the form.
                            </li>
                            <li>
                                Since pledges are organized by school, it is
                                very important to correctly identify your
                                child’s school in our database when filling out
                                the pledge form. To find your child’s school in
                                our database, type one or two key words from the
                                school name and hit the magnifier icon on the
                                form. Avoid words such as “the,” “elementary,”
                                “middle,” “junior,” and “school.”
                            </li>
                            <li>
                                If you cannot find your child’s current school
                                or next school, there is a place on the form to
                                enter the school’s name. Please be as specific
                                as possible here and avoid any acronyms. Please
                                try to enter the school’s name as it appears
                                officially.
                            </li>
                            <li>
                                On the pledge form, we ask for your child’s
                                current school and next school. Please enter the
                                current school your child attends and please
                                list the next school your child will attend,
                                which might be an upper elementary school,
                                intermediate school, junior high school, middle
                                school or high school.
                            </li>
                            <li>
                                Unplugged is only taking pledges from Canada at
                                the moment.
                            </li>
                        </ol>

                        <form className="form">
                            <h1 className="form__title text-[32px] text-[#2e4049]">
                                Unplugged Canada Pledge
                            </h1>

                            {formStatus === "pending guardian data" && (
                                <>
                                    <div className="form__stepper">
                                        <div className="form__step-orb form__step-orb--SELECTED"></div>
                                        <div className="form__step-orb"></div>
                                    </div>
                                    <GuardianForm
                                        guardianData={guardianData}
                                        setGuardianData={setGuardianData}
                                        postGuardian={() => setFormStatus("pending child data")}
                                    />
                                </>
                            )}

                            {formStatus === "pending child data" && (
                                <>
                                    <div className="form__stepper">
                                        <div className="form__step-orb"></div>
                                        <div className="form__step-orb form__step-orb--SELECTED"></div>
                                    </div>

                                    {errorMessage && (
                                        <>
                                            <Alert variant="outlined" severity="error">
                                                {errorMessage}
                                            </Alert>
                                        </>
                                    )}
                                    
                                    <ChildForm
                                        onSubmit={submit}
                                        childrenData={childrenData}
                                        setChildrenData={setChildrenData}
                                        addNewChild={addNewChild}
                                        showValidationError={showValidationError}
                                    />
                                </>
                            )}

                            {formStatus === "submitting" && (
                                <Box
                                    position="relative"
                                    className="space-y-8"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ height: "400px" }}
                                >
                                    <CircularProgress variant="indeterminate" />
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="textSecondary"
                                        fontSize="1rem"
                                    >Signing the pledge...</Typography>
                                </Box>
                            )}

                            {formStatus === "successfully submitted" && (
                                <>
                                    <Box
                                        position="relative"
                                        className="space-y-8"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ height: "400px" }}
                                    >
                                        <Alert variant="outlined" severity="success">
                                            Thank you for signing the pledge.
                                        </Alert>
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="textSecondary"
                                            fontSize="1rem"
                                        >You will receive a confirmation email shortly</Typography>

                                        <Fab
                                            sx={{ marginTop: 2 }}
                                            style={{ color: "white", width: "10rem" }}
                                            color="primary"
                                            variant="extended"
                                            onClick={signAgain}
                                        >
                                            Sign again
                                        </Fab>
                                    </Box>
                                </>
                            )}
                        </form>
                    </section>
                </div>
                <div className="flex flex-col gap-5">
                    <h3 className="text-gray-700 text-center text-2xl lg:text-[32px] font-bold  ">
                        Pledge Privacy
                    </h3>

                    <span className="text-center text-base lg:text-xl font-normal text-neutral-700">
                        Your surname will be shared with the other parents who
                        sign the Pledge, only when the Pledge becomes active. We
                        will not share your e-mail, child’s name, or their
                        school’s name with anyone and will never
                        post it publicly.
                    </span>
                </div>
            </main>

            <Footer />
        </div>
    )
}
